import FetchData from "../services/FetchDataService";
import { uuid } from "vue3-uuid";

import type { sfConfType } from "@/types/sfConfType";
import { toRaw } from "vue";

export default class InternalSF {
  dataToSend: any;
  config: sfConfType;
  headers: any;
  searchParams: any;

  constructor(sfConfig: sfConfType) {
    this.config = sfConfig;
    this.createHeaders();
  }

  addData(dataToSend: any) {
    this.dataToSend = toRaw(dataToSend);
    this.createClientSessionData();
  }

  send() {
    const config: RequestInit = {
      method: "POST",
      headers: this.headers,
      body: this.searchParams.toString(),
    };

    switch (process.env.NODE_ENV) {
      case "production":
        FetchData.sendWidgetData(
          "https://api.speedfin.pl/client_lead/create",
          config
        );
        break;
      default:
        FetchData.sendWidgetData(
          "https://api.zaufanyposrednik.pl/client_lead/create",
          config
        );
    }
  }

  createHeaders() {
    const myHeaders = new Headers();

    //myHeaders.append("Access-Control-Allow-Origin", "*"); // zawsze wylaczone
    myHeaders.append("Origin", "http://localhost:8080");
    myHeaders.append(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    );

    myHeaders.append("Authorization", `Bearer ${this.config.sendToken}`);

    this.headers = myHeaders;
  }

  createClientSessionData() {
    const clientSessionData = {
      filters: {
        ability: {
          applicants: {
            "1": {
              income: [
                {
                  id: 2,
                  value: this.dataToSend.filters.income,
                  period_from: null,
                  period_forward: null,
                },
              ],
              outcome: [
                {
                  id: 3,
                  value: 0,
                  limit: this.dataToSend.filters.outcome,
                  currency: 1,
                },
              ],
            },
          },
          people: this.dataToSend.filters.people,
          status: "Mieszkanie",
          region: "Miasta+wojewódzkie",
        },
        other: {
          isPolicyChecked: true,
          pathname: "/",
          fullUrl: window.location.href,
          type: "widget-ability",
          device: "",
          leadID: uuid,
          utmSource: "undefined",
          utmMedium: "undefined",
          utmCampaign: this.dataToSend.campaignId,
          selectedOffer: {
            id: this.dataToSend.productItem.id,
            bank: this.dataToSend.productItem.bank,
            ability: this.dataToSend.productItem.ability,
          },
          constants: {
            ageStep: 1,
            defaultAge: 30,
            defaultApplicants: [
              { incomeList: [], outcomeList: [] },
              { incomeList: [], outcomeList: [] },
            ],
            defaultCarsCount: 1,
            defaultMortgagePeriod: 30,
            defaultMortgageValue: 600000,
            defaultPeopleCount: 1,
            defaultSecureValue: 644445,
            defaultSurface: 50,
            maxAccountLimit: 6000000,
            maxAccountPeriod: 1000,
            maxAccountValue: 1000000,
            maxAge: 80,
            maxApplicantsCount: 5,
            maxCarsCount: 10,
            maxPeopleCount: 6,
            maxSecureValue: 2500000,
            minAccountLimit: 0,
            minAccountPeriod: 0,
            minAccountValue: 0,
            minAge: 18,
            minSecureValue: 10000,
            mortgageMaxPeriod: 35,
            mortgageMaxValue: 3000000,
            mortgageMinPeriod: 5,
            mortgageMinValue: 10000,
            mortgagePeriodStep: 1,
            mortgageRatio: 0.9,
            mortgageValueStep: 1000,
          },
          isFromOtodom: false,
          defaultValues: {
            mortgageValue: 400000,
            period: 30,
            secureValue: 444445,
            age: 30,
            purposeId: 1,
            sortId: 8,
          },
        },
        creditValue: 400000,
        creditPeriod: parseInt(this.dataToSend.filters.period) * 12,
        creditPurpose: 1,
        propertySort: 8,
        creditClientAge: this.dataToSend.filters.age,
        secureValue: 444445,
      },
    };

    const searchParams = new URLSearchParams({
      "client_lead[name]": this.dataToSend.user.name,
      "client_lead[email]": this.dataToSend.user.email,
      "client_lead[city]": this.dataToSend.user.city,
      "client_lead[phone]": this.dataToSend.user.phone,
      "client_lead[message]": this.dataToSend.user.message,
      "client_lead[siteSource]": window.location.href,
      "client_lead[firm]": "23",
      "client_lead[type]": "widget-ability",
      "client_lead[campaign]": this.dataToSend.campaignId,
      "client_lead[clientSessionData]": JSON.stringify(clientSessionData),
      "checkbox[regulations]": "on",
      "checkbox[policy]": "on",
    });

    this.searchParams = searchParams;
  }
}

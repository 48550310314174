import type { sfConfType } from "@/types/sfConfType";

interface LocalConfigType {
  sendUrlPath: string;
  sendFormat: string;
  sendMethod: string;
}

export default class ToUrlParams {
  dataToSend: any;
  config: LocalConfigType;

  constructor(sfConfig: sfConfType) {
    this.config = {
      sendUrlPath: sfConfig.sendUrlPath,
      sendFormat: sfConfig.sendFormat,
      sendMethod: sfConfig.sendMethod,
    };
  }

  addData(dataToSend: any) {
    this.dataToSend = dataToSend;
    console.log(dataToSend);
  }

  send() {
    const url = new URL(this.config.sendUrlPath);

    switch (this.config.sendFormat) {
      case "json":
        // TODO: code block
        break;
      case "string":
        url.searchParams.append(
          "prodId",
          String(this.dataToSend.productItem.id)
        );
        url.searchParams.append(
          "incomeValue",
          String(this.dataToSend.filters.income)
        );
        url.searchParams.append(
          "outcomeValue",
          String(this.dataToSend.filters.outcome)
        );
        url.searchParams.append(
          "creditClientAge",
          String(this.dataToSend.filters.age)
        );
        url.searchParams.append(
          "creditPeriod",
          String(this.dataToSend.filters.period)
        );
        url.searchParams.append(
          "peopleValue",
          String(this.dataToSend.filters.people)
        );
        break;
      case "array":
        // TODO: code block
        break;
      case "object":
        // TODO: code block
        break;
      default:
      // TODO: code block
    }

    if (this.config.sendMethod === "GET") {
      window.location.href = url.toString();
    }
  }
}

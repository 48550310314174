
import {
  Ref,
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  toRaw,
} from "vue";

import type { CustomWindow } from "@/types/window";
import type { sfConfType } from "@/types/sfConfType";
import type { ProductItemType } from "@/types/product";
import { showAsCurrency } from "@/services/showAsCurrency";
import { formToQuery } from "../services/DataToParams";
import FetchData from "../services/FetchDataService";
import { ExporterFactory } from "../services/exporterFactory";

import ComboInputSlider from "./ui/ComboInputSlider.vue";
import ProductItem from "./ProductItem.vue";
import ContactForm from "./ContactForm.vue";

declare let window: CustomWindow;

export default defineComponent({
  name: "WebWidget",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    contactTitle: {
      type: String,
      required: true,
      default: "",
    },
    filterButton: {
      type: String,
      required: true,
      default: "",
    },
    resultButton: {
      type: String,
      required: true,
      default: "",
    },
    contactFormButton: {
      type: String,
      required: true,
      default: "",
    },
    campaignId: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    ComboInputSlider,
    ProductItem,
    ContactForm,
  },
  setup: function (props) {
    const sfConfig: Ref<sfConfType | null> = ref(null);
    const incomeVal = ref(5000);
    const outcomeVal = ref(0);
    const ageVal = ref(30);
    const peopleVal = ref(1);
    const periodVal = ref(30);
    const modal = ref(false);

    const isLoading = ref(false);
    const showResults = ref(false);
    const resultList = ref<ProductItemType[]>([]);

    const dataToSend = ref({
      campaignId: "",
      user: {
        name: "",
        city: "",
        phone: "",
        email: "",
        message: "",
      },
      filters: {
        income: 0,
        outcome: 0,
        age: 0,
        people: 0,
        period: 0,
      },
      productItem: {
        id: 0,
        title: "",
        bank: "",
        ability: "",
      },
    });

    const showContact = (item: ProductItemType) => {
      if (sfConfig.value === null) {
        console.log("brak pliku konfiguracyjnego");
        return false;
      }

      dataToSend.value.productItem = {
        id: item.id,
        title: item.title,
        bank: item.bank.name,
        ability: showAsCurrency(item.ability.value, "PLN"),
      };

      if (
        sfConfig.value.sendType === "internalSF" ||
        sfConfig.value.sendType === "internalForm"
      ) {
        modal.value = !modal.value;
      } else {
        const externalExport = ExporterFactory.createExporter(sfConfig.value);
        externalExport.addData(dataToSend.value);
        externalExport.send();
      }
    };
    const closeContact = () => {
      modal.value = !modal.value;
    };

    const instance = getCurrentInstance();
    const sanitize = instance?.appContext.config.globalProperties.$sanitize;

    const changedIncomeVal = (value: number) => {
      incomeVal.value = value;
    };
    const changedOutcomeVal = (value: number) => {
      outcomeVal.value = value;
    };
    const changedAgeVal = (value: number) => {
      ageVal.value = value;
    };
    const changedPeopleVal = (value: number) => {
      peopleVal.value = value;
    };
    const changedPeriodVal = (value: number) => {
      periodVal.value = value;
    };

    const abilityCheckClick = (e: MouseEvent) => {
      if (e.target !== null) {
        (e.target as HTMLElement).blur();
      }
      abilityCheck();
    };

    const abilityCheck = () => {
      isLoading.value = true;
      nextTick(() => {
        const queryString = formToQuery(
          periodVal.value,
          peopleVal.value,
          outcomeVal.value,
          incomeVal.value,
          ageVal.value
        );

        const result = FetchData.countAbility(
          queryString,
          sfConfig.value?.readToken
        );

        result.then((resObj) => {
          if (resObj.status === 200) {
            resultList.value = resObj.data;
            //console.log("odebrane===", resObj);

            dataToSend.value.filters = {
              income: toRaw(incomeVal.value),
              outcome: toRaw(outcomeVal.value),
              age: toRaw(ageVal.value),
              people: toRaw(peopleVal.value),
              period: toRaw(periodVal.value),
            };

            showResults.value = true;
            isLoading.value = false;
          }
        });
      });
    };

    const resultInfo = computed(() => {
      return sanitize(sfConfig.value?.resultInfo);
    });

    onMounted(() => {
      const widgetConfig = window.widgetConfig;
      if (typeof widgetConfig !== "undefined") {
        sfConfig.value = widgetConfig;
      }
      dataToSend.value.campaignId = props.campaignId;
    });

    return {
      sfConfig,
      incomeVal,
      outcomeVal,
      ageVal,
      peopleVal,
      periodVal,
      changedIncomeVal,
      changedOutcomeVal,
      changedAgeVal,
      changedPeopleVal,
      changedPeriodVal,
      abilityCheckClick,
      abilityCheck,
      resultList,
      showResults,
      isLoading,
      showContact,
      closeContact,
      modal,
      showAsCurrency,

      dataToSend,

      resultInfo,
    };
  },
});

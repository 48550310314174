type queryObjType = {
  creditValue?: number;
  secureValue?: number;
  creditPeriod?: number;
  currencies?: any;
  ability?: any;
  fixedInterestRate?: any;
  variableInterestRate?: any;
  periodicalInterestRate?: any;
  eco?: any;
  bestBankOffer?: any;
  indexWibor?: any;
  indexWiron?: any;
  creditClientAge?: any;
  clientInternal?: any;
  clientExternal?: any;
};

export function formToQuery(
  periodVal: number,
  peopleVal: number,
  outcomeVal: number,
  incomeVal: number,
  ageVal: number
) {
  const queryObj: queryObjType = {};
  // pseudo DTO
  queryObj.creditValue = 250000;
  queryObj.secureValue = 350000;
  queryObj.creditPeriod = Number(periodVal) * 12;

  queryObj.currencies = {};
  queryObj.currencies[0] = 1;

  queryObj.ability = {};
  queryObj.ability.households = {};
  queryObj.ability.households[1] = {
    status: "Mieszkanie",
    region: "Miasta wojewódzkie",
    cars: 1,
    people: peopleVal,
    applicants: {},
  };

  queryObj.ability.households[1].applicants[1] = {
    name: "Wnioskodawca",
    birthday: "",
    incomes: {},
    outcomes: {},
  };

  queryObj.ability.households[1].applicants[1].incomes = {};
  queryObj.ability.households[1].applicants[1].incomes[1] = {
    id: 2,
    value: incomeVal,
    periodFrom: 6,
  };

  queryObj.ability.households[1].applicants[1].outcomes = {};
  queryObj.ability.households[1].applicants[1].outcomes[1] = {
    id: 7,
    limit: outcomeVal,
  };

  queryObj.fixedInterestRate = 1;
  queryObj.variableInterestRate = 1;
  queryObj.periodicalInterestRate = 0;
  queryObj.eco = 0;
  queryObj.bestBankOffer = 1;
  queryObj.indexWibor = 1;
  queryObj.indexWiron = 1;
  queryObj.creditClientAge = ageVal;
  queryObj.clientInternal = 1;
  queryObj.clientExternal = 1;

  return queryObj;
}
